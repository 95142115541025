import { graphql } from 'gatsby';
import React from 'react';
import Container from '../components/layout/Container/Container';
import PageLayout from '../components/layout/PageLayout/PageLayout';
import PageSection from '../components/layout/PageSection/PageSection';
import Divider from '../components/ui/Divider/Divider';
import LogoSlider from '../components/ui/Slider/Slider';
import HomeBanner from '../components/ui/Home/HomeBanner/HomeBanner';
import Testimonial from '../components/ui/Testimonial/Testimonial';
import PostsPerTopic from '../components/ui/Home/PostsPerTopic/PostsPerTopic';
import { PostsSelector } from '../components/utils/PostsSelector';
import Seo from '../components/utils/Seo';
import { useAnimationOnScroll } from '../components/utils/useAnimateOnScroll';
import { Direction } from '../globals';
import './index.scss';

interface HomeProps {
    data: {
        homeYaml: {
            about_section: AboutSectionBlock[];
            tabs_posts: PostsPerTopicBlock[];
        };
        allMdx: {
            nodes: PostDetails[];
        };
        topicsYaml: {
            topics: TopicDetails[];
        };
    };
}

const Home: React.FC<HomeProps> = ({ data }: HomeProps) => {
    const postsSelector = new PostsSelector(data.allMdx.nodes);
    useAnimationOnScroll();
    return (
        <PageLayout>
            <Seo isPost={false} />
            <HomeBanner horizontalCarouselTopics={data.topicsYaml.topics} />
            <PageSection className="what-is-section">
                <Container>
                    <div className="section-text">
                        <h2>The market leader in brand promotion.</h2>
                        <p>
                        ODO HOLDINGS LIMITED is a youth owned small medium enterprise with a young, innovative and dynamic professional personnel who have necessary skill to respond to customer’s desires and to respond to today’s challenging ever changing customer demand. Our primary objective is the provision of General Office Supplies, Branding and Printing services.
                        </p>
                        <p>
                        With reputational experience
in helping clients build reliable, secure and manageable brands, we ensure they meet their business objectives and purpose as an enterprise through outlook.
                        </p>
                    </div>

                    {/* <Group size={2}>
                        {data.homeYaml.about_section
                            .slice(0, 2)
                            .map((block, index) => {
                                return (
                                    <Block
                                        key={index}
                                        direction={Direction.vertical}
                                        aos="zoom-in"
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: block.icon
                                            }}
                                        ></span>
                                        <h3>{block.title}</h3>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: block.desc
                                            }}
                                        ></div>
                                    </Block>
                                );
                            })}
                    </Group>
                    <Group size={2}>
                        {data.homeYaml.about_section
                            .slice(2, 4)
                            .map((block, index) => {
                                return (
                                    <Block
                                        key={index}
                                        direction={Direction.vertical}
                                        aos="zoom-in"
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: block.icon
                                            }}
                                        ></span>
                                        <h3>{block.title}</h3>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: block.desc
                                            }}
                                        ></div>
                                    </Block>
                                );
                            })}
                    </Group> */}
                </Container>
            </PageSection>
            <PageSection className="posts-per-topic-section">
                <Container>
                    <div className="section-text">
                        <h2>Explore our products</h2>
                    </div>
                    <PostsPerTopic
                        {...postsSelector.getByTopic(data.homeYaml.tabs_posts)}
                    />
                </Container>
            </PageSection>
            <PageSection>
            <div className="section-text">
                        <h2>Testimonals</h2>
                    </div>
                <Testimonial>
                <div>
              <p>
                "I couldn't be happier with the excellent service provided by ODO HOLDINGS LIMITED. Their attention to detail and commitment to customer satisfaction is truly commendable. I highly recommend them for all your branding and printing needs."
              </p>
              <p>- Jane - Nairobi, Kenya</p>
            </div>
            <div>
              <p>
                "Working with ODO HOLDINGS LIMITED has been a pleasure from start to finish. Their team went above and beyond to ensure our promotional materials were delivered on time and exceeded our expectations. Thank you for your professionalism and dedication."
              </p>
              <p>- James - Mombasa, Kenya</p>
            </div>
            <div>
              <p>
                "I'm incredibly impressed with the quality of the branded merchandise I received from ODO HOLDINGS LIMITED. From T-shirts to banners, everything was top-notch and beautifully designed. I look forward to collaborating with them again in the future."
              </p>
              <p>- Grace - Kisumu, Kenya</p>
            </div>
            <div>
              <p>
                "ODO HOLDINGS LIMITED has been instrumental in helping us elevate our brand visibility. The attention to detail in their work is unmatched, and their ability to translate our vision into stunning promotional materials is truly remarkable. Thank you for your outstanding service."
              </p>
              <p>- Daniel - Eldoret, Kenya</p>
            </div>
            <div>
              <p>
                "I can't thank ODO HOLDINGS LIMITED enough for their exceptional service and professionalism. From concept to delivery, they guided us every step of the way and delivered high-quality products that exceeded our expectations. I would highly recommend them to anyone looking for reliable branding and printing services."
              </p>
              <p>- Mercy - Nakuru, Kenya</p>
            </div>
                </Testimonial>
                <Container>
                <div className="section-text">
                        <h2>We've worked with</h2>
                    </div>
                        <LogoSlider />
                </Container>
            </PageSection>
            {/* <PageSection className="topics-section">
                <Container>
                    <div className="section-text">
                        <h2>Topics</h2>
                        <p>
                            Get lorem dolor sed viverra. Id faucibus nisl
                            tincidunt eget nullam non nisi est sit. Aliquam
                            vestibulum morbi blandit cursus risus at ultrices.
                        </p>
                        <p>
                            Vel turpis nunc eget lorem. Scelerisque fermentum
                            dui faucibus in ornare quam viverra orci.
                        </p>
                    </div>
                    <Topics topics={data.topicsYaml.topics} />
                </Container>
            </PageSection> */}
            <Divider direction={Direction.horizontal} />
            {/* <PageSection className="more-articles-section">
                <Container>
                    <div className="section-text">
                        <h2>More articles</h2>
                    </div>
                    <MoreArticles
                        posts={data.allMdx.nodes.map(
                            (node) => node.frontmatter
                        )}
                    />
                </Container>
            </PageSection> */}
        </PageLayout>
    );
};

export const homeQuery = graphql`
    {
        homeYaml {
            tabs_posts {
                posts
                topic
            }
            about_section {
                desc
                icon
                title
            }
        }
        allMdx(
            filter: { frontmatter: { layout: { eq: "BlogPage" } } }
            sort: { fields: frontmatter___publishedAt, order: DESC }
        ) {
            nodes {
                frontmatter {
                    author
                    description
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    layout
                    suburl
                    tags
                    title
                }
            }
        }
        topicsYaml {
            topics {
                desc
                title
                url
                logo
            }
        }
    }
`;

export default Home;
